<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="800px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="60px">
      <el-table :data="form.tabs" style="width: 100%">
        <!-- <el-table-column prop="label" label="标题" width="180">
          <template slot-scope="{$index}">
            <el-form-item label-width="0px" style="margin-bottom: 0">
              <el-input v-model="form.tabs[$index].label" />
            </el-form-item>
          </template>
        </el-table-column> -->
        <el-table-column prop="component" label="组件">
          <template slot-scope="{$index}">
            <el-form-item label-width="0px" style="margin-bottom: 0">
              <el-cascader v-model="form.tabs[$index].component_cascader" @change="cascader_change($event, $index)" placeholder="请选择" style="width: 100%" :props="{ label: 'label', value: 'value' }" :options="component_cascader" />
            </el-form-item>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="200">
          <template slot-scope="{$index}">
            <el-button type="primary" icon="el-icon-plus" size="mini" @click="createRecord($index)">新增</el-button>
            <el-button v-if="form.tabs.length > 1" type="danger" icon="el-icon-delete" size="mini" @click="removeRecord($index)">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      
    </el-form>
    
  </el-dialog>
</template>

<script>

import _ from 'lodash';

import component_options from '@/config_components/options'
import component_cascader from '@/config_components/cascader'

export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {  },

      component_cascader,
    }
  },
  methods: {
    async show(form, options = {}) {
      this.form = _.assign({}, _.cloneDeep(form),
        _.size(form?.tabs) ? { tabs: _(form.tabs).map(o => _.assign({}, o, {
          component_cascader: _.find(component_options, { value: o.component })?.cascader_value
        })).cloneDeep().slice(0, 1) } : { tabs: [{}] }
      );
      this.options = _.assign({}, options, {})
      this.visible = true;
    },

    createRecord(index) {
      this.form = _.assign({}, this.form, {
        tabs: [
          ...this.form.tabs.slice(0, index + 1),
          {},
          ...this.form.tabs.slice(index + 1)
        ]
      })
    },

    removeRecord(index) {
      this.form = _.assign({}, this.form, {
        tabs: [
          ...this.form.tabs.slice(0, index),
          ...this.form.tabs.slice(index + 1)
        ]
      })
    },

    async confirmForm() {
      try {
        this.options.success && this.options.success(_.assign({}, this.form, {
          tabs: _(this.form.tabs).map(o => _.omit(o, ['component_cascader'])).value(),
          // tabs: _.filter(this.form.tabs, 'label')
        }))
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },

    cascader_change(e, index) {
      this.form = _.assign({}, this.form, {
        tabs: _.map(this.form.tabs, (o, idx) => idx === index ? _.assign({}, o, { component: _.last(e) }) : o)
      })
    }
  }
}
</script>